exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-article-contentful-article-slug-js": () => import("./../../../src/pages/article/{ContentfulArticle.slug}.js" /* webpackChunkName: "component---src-pages-article-contentful-article-slug-js" */),
  "component---src-pages-contentful-page-slug-js": () => import("./../../../src/pages/{ContentfulPage.slug}.js" /* webpackChunkName: "component---src-pages-contentful-page-slug-js" */),
  "slice---src-components-content-modules-article-copy-js": () => import("./../../../src/components/content-modules/article-copy.js" /* webpackChunkName: "slice---src-components-content-modules-article-copy-js" */),
  "slice---src-components-content-modules-article-image-js": () => import("./../../../src/components/content-modules/article-image.js" /* webpackChunkName: "slice---src-components-content-modules-article-image-js" */),
  "slice---src-components-content-modules-article-recipe-js": () => import("./../../../src/components/content-modules/article-recipe.js" /* webpackChunkName: "slice---src-components-content-modules-article-recipe-js" */),
  "slice---src-components-content-modules-article-two-images-js": () => import("./../../../src/components/content-modules/article-two-images.js" /* webpackChunkName: "slice---src-components-content-modules-article-two-images-js" */),
  "slice---src-components-main-menu-js": () => import("./../../../src/components/main-menu.js" /* webpackChunkName: "slice---src-components-main-menu-js" */)
}

